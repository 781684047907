<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.catalogLink') }}</b>
    </h3>
    <hr />
    <ul>
      <li>Buka Aplikasi Agen BeliRumah anda</li>
      <br />
      <li>
        Pilih menu “Akun” di bagian kanan paling bawah beranda anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Dibagian atas akan muncul “Link Website Katalog Anda” yang bisa anda langsung klik untuk
        masuk ke Halaman Website Katalog Anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image30.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Atau, pilih tanda “share” untuk mendapatkan link untuk digunakan share ke klien atau rekan
        anda melalui media sosial yang anda miliki.
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
