<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.coinUsage') }}</b>
    </h3>
    <hr />
    <h3>
      <b>15.1 Mengetahui histori pemakaian koin melalui melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Akun” di sisi kanan bawah beranda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pada bagian “Akun” pilih opsi “Pemakaian Koin”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image68.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman “Pemakaian Koin" anda dapat melihat record penggunaan koin yang sudah anda
        lakukan dan juga nama listing yang anda miliki
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image70.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Anda bisa mengetahui spesifik histori penggunaan koin anda terhadap satu listing spesifik
        dengan mengetikkan judul listing yang anda miliki di kolom yang disediakan
      </li>
    </ul>
    <hr />
    <h3>
      <b>15.2 Mengetahui histori pemakaian koin melalui Browser PC/Laptop</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu profil di sisi kanan atas dari halaman beranda anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image40.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Klik opsi “Pemakaian Koin”</li>
      <br />
      <li>Pilih menu “Pemakaian Koin” di box sisi kiri</li>
      <br />
      <li>
        Di halaman “Pemakaian Koin" anda dapat melihat record penggunaan koin yang sudah anda
        lakukan dan juga nama listing yang anda miliki
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image72.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Anda bisa mengetahui spesifik histori penggunaan koin anda terhadap satu listing spesifik
        dengan mengetikkan judul listing yang anda miliki di kolom yang disediakan
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
