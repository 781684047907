<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.repost') }}</b>
    </h3>
    <hr />
    <h3>
      <b>8.1 Repost melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Listing” di bagian bawah, kedua dari kanan di beranda melalui Aplikasi Mobile
        (iOS/Android)
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image19.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Klik “Aktif”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image22.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman ini anda akan melihat seluruh listing aktif yang anda miliki, dan untuk merepost
        listing tersebut, ada beberapa opsi repost yang dapat anda gunakan:
        <ol>
          <li>
            Repost Biasa. Merupakan Repost normal dengan konsumsi 1 koin per listing yang anda
            repost
            <ul>
              <li>
                Tekan tombol titik tiga “⋮” di sisi kanan atas listing card yang Ingin anda repost
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image7.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Pilih opsi “Repost (1 koin)”</li>
              <li>
                Akan muncul halaman detail “Repost Listing Anda” untuk konfirmasi kembali perihal
                repost listing yang akan mengkonsumsi 1 koin dan memberikan informasi jumlah koin
                yang saat ini anda miliki.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image1.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Tekan “Repost Sekarang”</li>
            </ul>
          </li>
          <li>
            Repost Beberapa Listing. Anda dapat melakukan repost beberapa listing anda di waktu yang
            sama.
            <ul>
              <li>
                Tekan tombol “Repost Beberapa Listing” berwarna merah di sisi bawah Tengah
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image2.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Di halaman “Repost Beberapa Listing”, silahkan klik kolom “Cari Listing Anda”
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image3.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Akan muncul beberapa Listing Aktif milik anda yang bisa anda pilih dengan mencentang
                box di sisi kanan listing, atau silahkan cari listing spesifik yang anda Inginkan
                dengan ketik bagian dari judul listing di box pencarian yang disediakan
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image4.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Tekan tombol “OK”</li>
              <li>
                Akan muncul informasi listing mana saja yang sudah anda pilih untuk direpost
                bersamaan, tekan tombol “Repost Beberapa Listing”
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image5.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Selanjutnya akan muncul jumlah banyak listing yang sudah dipilih dan jumlah koin
                yang akan dikonsumsi untuk proses repost tersebut.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image6.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Tekan tombol “Repost Sekarang”</li>
            </ul>
          </li>
          <li>
            Jadwalkan Repost. Jika anda Ingin menjadwalkan untuk melakukan repost di listing
            tertentu untuk waktu tertentu di masa yang akan datang.
            <ul>
              <li>
                Tekan tombol titik tiga “⋮” di sisi kanan atas listing card yang Ingin anda
                jadwalkan untuk di repost
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image7.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Pilih opsi “Jadwalkan Repost”</li>
              <li>
                Akan muncul halaman “Jadwalkan Repost” untuk memilih jadwal kapan listing tersebut
                Ingin di repost di masa yang akan datang.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image8.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Tekan “Tambah Jadwal” dan akan muncul pilihan hari dan jam berapa anda Ingin listing
                tersebut direpost secara otomatis. Jangan lupa untuk tentukan kapan repost otomatis
                tersebut anda inginkan untuk berakhir. Anda bisa menambahkan skedul “Tambah Jadwal”
                sebanyak yang anda perlukan.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image9.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Tekan “Simpan Jadwal”</li>
            </ul>
          </li>
        </ol>
      </li>
    </ul>
    <hr />
    <h3>
      <b>8.2 Repost melalui Browser Laptop/PC</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Aktif” di halaman beranda, melalui browser Laptop/PC anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image10.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman ini anda akan melihat seluruh listing aktif yang anda miliki, dan untuk merepost
        listing tersebut, ada beberapa opsi repost yang dapat anda gunakan:
        <ol>
          <li>
            Repost Biasa. Merupakan Repost normal dengan konsumsi 1 koin per listing yang anda
            repost
            <ul>
              <li>
                Tekan tombol titik tiga “⋮” di sisi kanan atas listing card yang Ingin anda repost
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image27.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Pilih opsi “Repost (1 koin)”</li>
              <li>
                Atau, anda dapat langsung tekan tombol “repost” di halaman listing card anda
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image32.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Akan muncul halaman detail “Repost Listing Anda” untuk konfirmasi kembali perihal
                repost listing yang akan mengkonsumsi 1 koin dan memberikan informasi jumlah koin
                yang saat ini anda miliki.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image33.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Tekan “Repost Sekarang”</li>
            </ul>
          </li>
          <li>
            Repost Beberapa Listing. Anda dapat melakukan repost beberapa listing anda di waktu yang
            sama.
            <ul>
              <li>
                Tekan tombol “Repost Beberapa Listing” berwarna merah di sisi bawah Tengah
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image34.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Di halaman “Repost Beberapa Listing”, silahkan klik kolom “Cari Listing Anda”
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image35.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Akan muncul beberapa Listing Aktif milik anda yang bisa anda pilih dengan klik
                listing yang diinginkan
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image36.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Tekan tombol “Repost Beberapa Listing”
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image37.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Selanjutnya akan muncul jumlah banyak listing yang sudah dipilih dan jumlah koin
                yang akan dikonsumsi untuk proses repost tersebut.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image38.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Tekan tombol “Repost Sekarang”</li>
            </ul>
          </li>
          <li>
            Jadwalkan Repost. Jika anda Ingin menjadwalkan untuk melakukan repost di listing
            tertentu untuk waktu tertentu di masa yang akan datang.
            <ul>
              <li>
                Tekan tombol titik tiga “⋮” di sisi kanan atas listing card yang Ingin anda
                jadwalkan untuk di repost
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image27.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Pilih opsi “Jadwalkan Repost”</li>
              <li>
                Atau, anda dapat langsung tekan tombol “Jadwalkan Repost” di halaman listing card
                anda
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image39.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Akan muncul halaman “Jadwalkan Repost” untuk memilih jadwal kapan listing tersebut
                Ingin di repost di masa yang akan datang.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image31.png"
                    alt=""
                  />
                </div>
              </li>
              <li>
                Tekan “Tambah Jadwal” dan akan muncul pilihan hari dan jam berapa anda Ingin listing
                tersebut direpost secara otomatis. Jangan lupa untuk tentukan kapan repost otomatis
                tersebut anda inginkan untuk berakhir. Anda bisa menambahkan skedul “Tambah Jadwal”
                sebanyak yang anda perlukan.
                <div class="wrapper--img">
                  <img
                    src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image25.png"
                    alt=""
                  />
                </div>
              </li>
              <li>Tekan “Simpan Jadwal”</li>
            </ul>
          </li>
        </ol>
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
