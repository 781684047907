<template>
  <default-layout>
    <div class="container help--container">
      <banner :banner-text="bannerText" />
      <div class="help--wrapper">
        <div class="left--col">
          <page-select></page-select>
        </div>
        <div class="right--col" v-if="selectedHelp">
          <div class="help--content">
            <div class="content--text">
              <AccountRegistration
                v-if="selectedHelp.url === 'account-registration'"
              ></AccountRegistration>
              <AccountPurchase
                v-else-if="selectedHelp.url === 'account-purchase'"
              ></AccountPurchase>
              <Membership v-else-if="selectedHelp.url === 'membership'"></Membership>
              <Upgrade v-else-if="selectedHelp.url === 'upgrade'"></Upgrade>
              <ListingSlot v-else-if="selectedHelp.url === 'listing-slot'"></ListingSlot>
              <Coin v-else-if="selectedHelp.url === 'coin'"></Coin>
              <Upload v-else-if="selectedHelp.url === 'upload'"></Upload>
              <Repost v-else-if="selectedHelp.url === 'repost'"></Repost>
              <Deactivate v-else-if="selectedHelp.url === 'deactivate'"></Deactivate>
              <CatalogLink v-else-if="selectedHelp.url === 'catalog-link'"></CatalogLink>
              <CoBroke v-else-if="selectedHelp.url === 'co-broke'"></CoBroke>
              <EditProfile v-else-if="selectedHelp.url === 'edit-profile'"></EditProfile>
              <ReferralCode v-else-if="selectedHelp.url === 'referral-code'"></ReferralCode>
              <Password v-else-if="selectedHelp.url === 'password'"></Password>
              <CoinUsage v-else-if="selectedHelp.url === 'coin-usage'"></CoinUsage>
              <Inbox v-else-if="selectedHelp.url === 'inbox'"></Inbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/utils/page-banner';
import PageSelect from '@/components/utils/page-select';
import DefaultHeadMixin from '@/mixins/defaultHead';
import { mapState } from 'vuex';
import AccountRegistration from '@/components/static-page/help/account-registration.vue';
import AccountPurchase from '@/components/static-page/help/account-purchase.vue';
import Membership from '@/components/static-page/help/membership.vue';
import Upgrade from '@/components/static-page/help/upgrade.vue';
import ListingSlot from '@/components/static-page/help/listing-slot.vue';
import Coin from '@/components/static-page/help/coin.vue';
import Upload from '@/components/static-page/help/upload.vue';
import Repost from '@/components/static-page/help/repost.vue';
import Deactivate from '@/components/static-page/help/deactivate.vue';
import CatalogLink from '@/components/static-page/help/catalog-link.vue';
import CoBroke from '@/components/static-page/help/co-broke.vue';
import EditProfile from '@/components/static-page/help/edit-profile.vue';
import ReferralCode from '@/components/static-page/help/referral-code.vue';
import Password from '@/components/static-page/help/password.vue';
import CoinUsage from '@/components/static-page/help/coin-usage.vue';
import Inbox from '@/components/static-page/help/inbox.vue';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    AccountPurchase,
    AccountRegistration,
    Membership,
    Upgrade,
    ListingSlot,
    Coin,
    Upload,
    Repost,
    Deactivate,
    CatalogLink,
    CoBroke,
    EditProfile,
    ReferralCode,
    Password,
    CoinUsage,
    Inbox,
    DefaultLayout,
    Banner,
    PageSelect,
  },
  computed: {
    ...mapState({
      selectedHelp: (state) => state.help.selectedHelp,
      helpList: (state) => state.help.helpList,
    }),
  },
  data: () => ({
    bannerText: 'FAQ.helpCenter',
  }),
  mounted() {
    if (this.$route.params.type) {
      const help = this.helpList.find((help) => {
        return help.url === this.$route.params.type;
      });
      this.$store.commit('help/SET_SELECTED_HELP', help);
    } else {
      this.$store.commit('help/SET_SELECTED_HELP', this.helpList[0]);
    }
  },
};
</script>
