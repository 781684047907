<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.upload') }}</b>
    </h3>
    <hr />
    <br />
    <ul>
      <li>
        Pilih menu “Tambah Listing” di halaman beranda, melalui browser Laptop/PC anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image18.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Atau, pilih menu “Listing” di bagian bawah, kedua dari kanan di beranda melalui Aplikasi
        Mobile (iOS/Android), dan klik “Tambah Listing”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image19.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pastikan untuk mengisi minimal kolom-kolom yang berikan tanda Bintang (*) yakni:
        <ol>
          <li>Tipe Properti</li>
          <li>Provinsi</li>
          <li>Kota/Kabupaten</li>
          <li>Area</li>
          <li>Tekan “Selanjutnya”</li>
          <li>Judul Listing</li>
          <li>Deskripsi</li>
          <li>Kelola Foto (Foto maksimal upload adalah 20 foto dengan format JPEG/PNG)</li>
          <li>Harga Properti</li>
          <li>Detail Properti</li>
          <li>Sertifikat Unit</li>
        </ol>
      </li>
      <br />
      <li>
        Selanjutnya Pilih 3 Opsi tersedia:
        <ol>
          <li>
            Aktivasi Sekarang. Apabila data listing yang anda buat sudah lengkap dan Ingin segera
            ditayangkan. Hal ini akan mengkonsumsi 1 koin milik anda.
          </li>
          <li>
            Simpan. Apabila data listing anda belum lengkap atau tidak Ingin ditayangkan segera.
            Namun untuk bisa simpan ke draft, anda harus terlebih dahulu melengkapi seluruh kolom
            penting yang tersedia.
          </li>
          <li>
            Kembali. Jika anda Ingin kembali ke halaman sebelumnya untuk mengubah tipe properti atau
            lokasi listing
          </li>
        </ol>
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image21.png"
            alt=""
          />
        </div>
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
