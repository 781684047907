<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.cobroke') }}</b>
    </h3>
    <hr />
    <ul>
      <li>Buka Aplikasi Agen BeliRumah anda</li>
      <br />
      <li>
        Pilih menu “Akun” di bagian kanan paling bawah beranda anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Tekan banner “Ganti Mode”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image23.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Atau, geser ke bawah dan pilih opsi “Ganti ke mode Co-Broke Mitra Proyek” di bagian “Jual
        Properti”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image24.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Anda akan masuk ke halaman khusus Co-Broke dimana anda akan menemukan Rekan-rekan Mitra
        Proyek BeliRumah
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image50.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pada halaman Beranda, Di Bagian “Proyek Rumah” atau “Proyek Apartemen”, silahkan klik “lihat
        semua”
      </li>
      <br />
      <li>
        Anda bisa memilih proyek-proyek yang tersedia dan bisa anda sortir berdasarkan kualifikasi
        tertentu dengan menu “filter”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image51.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Silahkan pilih proyek pilihan anda dan anda akan menemukan detail dari proyek tersebut
        dimana salah satunya terdapat informasi mengenai besaran komisi yang diberikan oleh Mitra
        Proyek kami apabila anda berhasil membantu penjualan mereka
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image52.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Tekan tombol “Hubungi Kami” untuk terhubung dengan tim CS kami, yang akan menginformasikan
        nomor PIC Mitra Proyek terkait
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image53.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Tekan “Jual Properti Ini” dan nantinya data anda akan terdaftar di database BeliRumah. Data
        ini akan digunakan sebagai referensi untuk Leads yang masuk melalui aktivitas marketing kami
        terkait proyek terkait.
      </li>
      <br />
      <li>
        Namun agent cobroke mitra proyek yang berhak menerima adalah agent yang paling terbaru aktif
        di BeliRumah.co
      </li>
      <br />
      <li>
        Seluruh proyek yang anda Ingin bantu dapat ditemukan di halaman “Project” yang dapat anda
        klik di bar paling bawah
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
