<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.membership') }}</b>
    </h3>
    <hr />
    <h3>
      <b>3.1 Pembayaran melalui Aplikasi Mobile</b>
    </h3>
    <br />
    <ul>
      <li>
        Di Halaman Checkout, pastikan item pembelian sudah sesuai dengan yang Ingin anda beli
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image75.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pilih metode pembayaran yang ingin anda gunakan.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image73.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Jika anda memiliki kode Promo, silahkan input di kolom yang disediakan lalu tekan “Cek
        Kode”.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image74.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Akan muncul “Kode Valid” jika kode yang anda input sudah betul dan masih berlaku</li>
      <br />
      <li>
        Atau, akan muncul “Promo tidak ditemukan” jika kode yang anda input salah atau habis masa
        berlakunya
      </li>
      <br />
      <li>
        Di Kotak “Ringkasan Pesanan”, anda dapat menemukan detail item yang anda beli dan dikurangi
        diskon yang anda gunakan jika ada
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image77.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Tekan tombol “Lakukan Pembayaran”</li>
    </ul>
    <hr />
    <h3>
      <b>3.2 Pembayaran melalui Browser PC/Laptop</b>
    </h3>
    <br />
    <ul>
      <li>
        Di Halaman Checkout, pastikan item pembelian sudah sesuai dengan yang Ingin anda beli
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image78.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pilih metode pembayaran yang ingin anda gunakan.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image79.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Jika anda memiliki kode Promo, silahkan input di kolom yang disediakan lalu tekan “Cek
        Kode”.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image80.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Akan muncul “Kode Valid” jika kode yang anda input sudah betul dan masih berlaku</li>
      <br />
      <li>
        Atau, akan muncul “Promo tidak ditemukan” jika kode yang anda input salah atau habis masa
        berlakunya
      </li>
      <br />
      <li>
        Di Kotak “Ringkasan Pembelian”, anda dapat menemukan detail item yang anda beli dan
        dikurangi diskon yang anda gunakan jika ada
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image81.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Tekan tombol “Lakukan Pembayaran”</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
