<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.coin') }}</b>
    </h3>
    <hr />
    <h3>
      <b>6.1 Cara Membeli Koin melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Di halaman beranda, anda bisa langsung klik “Tambah Koin”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image61.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Atau, Pilih “Beli Paket” di toolbar bagian bawah aplikasi mobile anda, berada di posisi
        kedua sisi kanan bawah aplikasi
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image58.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pilih Menu “Beli Koin” dan pilih Jumlah Koin yang anda ingin beli
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image62.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Geser ke bawah dan tekan tombol “Lanjut ke Checkout”</li>
      <br />
      <li>Lanjut ke bagian Cara Pembayaran</li>
    </ul>
    <hr />
    <h3>
      <b>6.2 Pembayaran melalui Browser PC/Laptop</b>
    </h3>
    <br />
    <ul>
      <li>
        Di halaman beranda, anda bisa langsung klik “Tambah Koin”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image14.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Atau, Pilih “Beli Paket” di toolbar bagian kiri atas tampilan browser anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image16.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pilih Menu “Beli Koin”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image17.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Geser ke bawah dan tekan tombol “Lanjut ke Checkout”</li>
      <br />
      <li>Lanjut ke bagian Cara Pembayaran</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
