<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.upgrade') }}</b>
    </h3>
    <hr />
    <h3>
      <b>4.1 Upgrade Paket Keanggotaan melalui Aplikasi Mobile (Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih “Upgrade Membership” di bagian atas halaman beranda pada tampilan aplikasi mobile anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image82.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Atau, pilih “Beli Paket” di toolbar bagian bawah aplikasi mobile anda, berada di posisi
        kedua sisi kanan bawah aplikasi
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image58.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih menu “Upgrade Membership”</li>
      <br />
      <li>
        Pilih tipe membership yang anda inginkan dengan menekan kotak paket membership
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image83.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Geser ke bawah dan tekan tombol “Lanjut ke Checkout”</li>
      <br />
      <li>Lanjut ke bagian Cara Pembayaran</li>
    </ul>
    <hr />
    <h3>
      <b>4.2 Upgrade Paket Keanggotaan melalui Browser PC/Laptop</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih “Upgrade Membership” di halaman beranda pada tampilan browser anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image56.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Atau, pilih “Beli Paket” di toolbar bagian kiri atas tampilan browser anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image16.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pilih tipe membership yang anda inginkan dengan menekan kotak paket membership
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image57.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Tekan tombol “Lanjut ke Checkout”</li>
      <br />
      <li>Lanjut ke bagian Cara Pembayaran</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
