<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.referralCode') }}</b>
    </h3>
    <hr />
    <h3>
      <b>13.1 Menemukan kode referral agen melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Akun” di sisi kanan bawah beranda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pada bagian “Akun” pilih opsi “Kode Referral”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image49.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman “Kode referral" anda dapat menemukan berbagai informasi terkait cara kerja dan
        ketentuan penggunaan kode referral
      </li>
      <br />
      <li>
        Anda dapat share Kode Referral anda dengan menekan tombil “Bagikan”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image43.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Anda juga dapat melihat total Kode Referal yang sudah anda dapatkan dari keseluruhan kode
        referral yang digunakan oleh teman anda
      </li>
    </ul>
    <hr />
    <h3>
      <b>13.2 Menemukan kode referral agen melalui Browser PC/Laptop</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu profil di sisi kanan atas dari halaman beranda anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image40.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Klik opsi “Akun”</li>
      <br />
      <li>
        Pilih menu “Kode Referral” di box sisi kiri
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image44.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman “Kode referral" anda dapat menemukan berbagai informasi terkait cara kerja dan
        ketentuan penggunaan kode referral
      </li>
      <br />
      <li>
        Anda dapat share Kode Referral anda dengan menekan tombil “Bagikan”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image45.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Anda juga dapat melihat total Kode Referal yang sudah anda dapatkan dari keseluruhan kode
        referral yang digunakan oleh teman anda
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
