<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.editProfile') }}</b>
    </h3>
    <hr />
    <h3>
      <b>12.1 Cara mengubah profil agen melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Akun” di sisi kanan bawah beranda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pada bagian “Akun” pilih opsi “Ubah Profil”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image54.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman ubah profil ini anda daoat menambahkan detail berikut:
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image55.png"
            alt=""
          />
        </div>
        <ol>
          <li>
            Tambahkan Foto Profil. Pastikan untuk gunakan foto profesional anda untuk menarik
            kepercayaan calon buyer agar meyakinkan menghubungi anda
          </li>
          <li>Ubah No HP anda. Namun membutuhkan konfirmasi kode OTP</li>
          <li>Area Spesialisasi</li>
          <li>Spesialisasi Properti</li>
          <li>Harga Properti</li>
          <li>
            Media Sosial. Anda dapat menambahkan link media sosial bisnis anda, pastikan tambahkan
            link sebagai berikut https://www.instagram/ (username anda) dan lain-lain
          </li>
        </ol>
      </li>
      <br />
      <li>Tekan “Simpan Profil”</li>
    </ul>
    <hr />
    <h3>
      <b>12.2 Cara mengubah profil agen melalui Browser Laptop/PC</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu profil di sisi kanan atas dari halaman beranda anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image40.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Klik opsi “Akun”</li>
      <br />
      <li>
        Di halaman ubah profil ini anda dapat menambahkan detail berikut:
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image48.png"
            alt=""
          />
        </div>
        <ol>
          <li>
            Tambahkan Foto Profil. Pastikan untuk gunakan foto profesional anda untuk menarik
            kepercayaan calon buyer agar meyakinkan menghubungi anda
          </li>
          <li>Ubah No HP anda. Namun membutuhkan konfirmasi kode OTP</li>
          <li>Area Spesialisasi</li>
          <li>Spesialisasi Properti</li>
          <li>Harga Properti</li>
          <li>
            Media Sosial. Anda dapat menambahkan link media sosial bisnis anda, pastikan tambahkan
            link sebagai berikut https://www.instagram/ (username anda) dan lain-lain
          </li>
        </ol>
      </li>
      <br />
      <li>Tekan “Simpan Perubahan”</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
