<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.inbox') }}</b>
    </h3>
    <hr />
    <h3>
      <b>16.1 Melihat kotak pesan melalui melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Akun” di sisi kanan bawah beranda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pada bagian “Akun” pilih opsi “Kotak Pesan”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image76.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman “Kotak Pesan" anda dapat melihat seluruh pesan masuk dari calon buyer/visitor
        yang tertarik dengan listing aktif anda. Informasi akan meliputi Detail Link Listing, Alamat
        Email dan No HP milik calon buyer yang dapat anda hubungi segera.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image12.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Bila anda klik tanda titik tiga “⋮” di sisi kanan atas, maka anda bisa memasukkan pesan
        tersebut ke Arsip, Hapus atau Tandai belum dibaca.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image11.png"
            alt=""
          />
        </div>
      </li>
    </ul>
    <hr />
    <h3>
      <b>16.2 Melihat kotak pesan melalui Browser PC/Laptop</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih opsi “Kotak Pesan” di opsi Bar bagian aras beranda anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image15.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman “Kotak Pesan" anda dapat melihat seluruh pesan masuk dari calon buyer/visitor
        yang tertarik dengan listing aktif anda. Informasi akan meliputi Detail Link Listing, Alamat
        Email dan No HP milik calon buyer yang dapat anda hubungi segera.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image13.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di sisi kanan atas, maka anda bisa memasukkan pesan tersebut ke Arsip, Hapus atau Tandai
        belum dibaca.
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
