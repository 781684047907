<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.password') }}</b>
    </h3>
    <hr />
    <h3>
      <b>14.1 Cara ubah password akun agen melalui melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Akun” di sisi kanan bawah beranda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image29.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Pada bagian “Akun” pilih opsi “Ubah Kata Sandi”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image46.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman “Ubah Kata Sandi" anda dapat mengisi kolom “Kata Sandi Lama” dan mendaftarkan
        Password baru yang anda inginkan di 2 kolom “Kata Sandi Baru” dan “Konfirmasi Kata Sandi”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image47.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pastikan Password baru yang anda gunakan memiliki minimal 8 kata/huruf</li>
      <br />
      <li>Tekan tombol “Ubah Kata Sandi”</li>
    </ul>
    <hr />
    <h3>
      <b>14.2 Cara ubah password akun agen melalui melalui Browser PC/Laptop</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu profil di sisi kanan atas dari halaman beranda anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image40.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Klik opsi “Akun”</li>
      <br />
      <li>
        Pilih menu “Ubah Kata Sandi” di box sisi kiri
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image41.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Di halaman “Ubah Kata Sandi" anda dapat mengisi kolom “Kata Sandi Lama” dan mendaftarkan
        Password baru yang anda inginkan di 2 kolom “Kata Sandi Baru” dan “Konfirmasi Kata Sandi”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image42.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pastikan Password baru yang anda gunakan memiliki minimal 8 kata/huruf</li>
      <br />
      <li>Tekan tombol “Ubah Kata Sandi”</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
