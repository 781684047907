<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.accountRegistration') }}</b>
    </h3>
    <hr />
    <h3>
      <b>1.1 Mendaftar melalui Aplikasi Mobile (iOS dan Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        <div>
          Instal aplikasi “agen belirumah” di Apple Appstore atau Android Playstore di perangkat
          mobile anda. Silahkan gunakan link berikut untuk install aplikasi mobile “agen belirumah”.
          <br />Android Google Playstore:
          <a :href="androidUrl" target="_blank">{{ androidUrl }}</a>
          <br />iOS App Store:
          <a :href="iosUrl" target="_blank">{{ iosUrl }}</a>
        </div>
      </li>
      <br />
      <li>
        Tekan tombol “Daftar Sekarang”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image63.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Lengkapi poin-poin berikut:
        <ol>
          <li>Nama</li>
          <li>Email</li>
          <li>Kata sandi (memiliki minimal 8 huruf/kata)</li>
          <li>
            Konfirmasi kata sandi (pastikan sama dengan yang anda gunakan di kolom “kata sandi”
          </li>
          <li>
            Kode referral (tidak wajib dan hanya digunakan jika anda mengetahui belirumah dari rekan
            anda yang sudah terdaftar aktif di BeliRumah sebelumnya)
          </li>
          <li>
            Tipe Agen (pilih antara agen independent atau pilih anggota kantor properti jika ada
            saat ini tergabung dengan kantor agensi tertentu)
          </li>
          <li>
            NIB (tidak wajib, jika anda sudah bergabung dengan kantor agensi properti tertentu, maka
            anda akan otomatis menggunakan NIB milik kantor anda yang sudah tersedia di database
            kami)
          </li>
          <li>
            Area Spesialisasi (pilih area referensi tempat anda memasang iklan listing, bisa pilih
            lebih dari satu)
          </li>
        </ol>
      </li>
      <br />
      <li>Tekan tombol “Daftar”</li>
      <br />
      <li>
        Setelahnya anda akan diminta untuk cek kotak masuk (inbox) dari email yang anda gunakan
        untuk mendaftar akun. Cukup tekan tombol “aktivasi akun”
      </li>
      <br />
      <li>
        Selanjutnya silahkan anda akan input nomor handphone yang anda miliki dan nantinya akan kami
        kirimkan kode OTP melalui SMS ke nomor tersebut. Pastikan nomor yang digunakan adalah nomor
        aktif dan tersedia di Whatsapp
      </li>
    </ul>
    <hr />
    <h3>
      <b>1.2 Mendaftar melalui Browser PC/Laptop (Google Chrome/Firefox/Edge/Safari)</b>
    </h3>
    <br />
    <ul>
      <li>
        <div>Ketik atau masukan alamat berikut ke kolom “address bar”:</div>
        <a href="https://agen.belirumah.co/" target="_blank">https://agen.belirumah.co/</a>
      </li>
      <br />
      <li>
        Tekan tombol “Daftar Sekarang”
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image65.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Lengkapi poin-poin berikut:
        <ol>
          <li>Nama</li>
          <li>Email</li>
          <li>Kata sandi (memiliki minimal 8 huruf/kata)</li>
          <li>
            Konfirmasi kata sandi (pastikan sama dengan yang anda gunakan di kolom “kata sandi”
          </li>
          <li>
            Kode referral (tidak wajib dan hanya digunakan jika anda mengetahui belirumah dari rekan
            anda yang sudah terdaftar aktif di BeliRumah sebelumnya)
          </li>
          <li>
            Tipe Agen (pilih antara agen independent atau pilih anggota kantor properti jika ada
            saat ini tergabung dengan kantor agensi tertentu)
          </li>
          <li>
            NIB (tidak wajib, jika anda sudah bergabung dengan kantor agensi properti tertentu, maka
            anda akan otomatis menggunakan NIB milik kantor anda yang sudah tersedia di database
            kami)
          </li>
          <li>
            Area Spesialisasi (pilih area referensi tempat anda memasang iklan listing, bisa pilih
            lebih dari satu)
          </li>
        </ol>
      </li>
      <br />
      <li>Tekan tombol “Daftar”</li>
      <br />
      <li>
        Setelahnya anda akan diminta untuk cek kotak masuk (inbox) dari email yang anda gunakan
        untuk mendaftar akun. Cukup tekan tombol “aktivasi akun”
      </li>
      <br />
      <li>
        Selanjutnya silahkan anda akan input nomor handphone yang anda miliki dan nantinya akan kami
        kirimkan kode OTP melalui SMS ke nomor tersebut. Pastikan nomor yang digunakan adalah nomor
        aktif dan tersedia di Whatsapp
      </li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountRegistration',
  data: () => ({
    androidUrl: 'https://play.google.com/store/apps/details?id=com.beliruma.beliruma_agent&pli=1',
    iosUrl: 'https://apps.apple.com/us/app/agen-belirumah/id1599683143',
  }),
};
</script>

<style></style>
