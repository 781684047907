<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.listingSlot') }}</b>
    </h3>
    <hr />
    <h3>
      <b>5.1 Cara Membeli Slot Listing Tambahan melalui Aplikasi Mobile (Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih “Beli Paket” di toolbar bagian bawah aplikasi mobile anda, berada di posisi kedua sisi
        kanan bawah aplikasi
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image58.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih Menu “Beli Slot Listing”</li>
      <br />
      <li>
        Masukkan jumlah slot listing dan jangka waktu slot listing yang anda inginkan. Jika detail
        item memenuhi salah satu di antara minimal 50 slot listing atau jangka waktu slot listing
        minimal 6 bulan maka anda berhak mendapatkan diskon spesial. Silahkan hubungi CS kami
        melalui tombol yang disediakan untuk mendapatkan kode promo.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image59.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Geser ke bawah dan tekan tombol “Lanjut ke Checkout”</li>
      <br />
      <li>Lanjut ke bagian Cara Pembayaran</li>
    </ul>
    <hr />
    <h3>
      <b>5.2 Cara Membeli Slot Listing Tambahan melalui Browser Laptop/PC</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih “Beli Paket” di toolbar bagian kiri atas tampilan browser anda
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image16.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih Menu “Beli Slot Listing”</li>
      <br />
      <li>
        Masukkan jumlah slot listing dan jangka waktu slot listing yang anda inginkan. Jika detail
        item memenuhi salah satu di antara minimal 50 slot listing atau jangka waktu slot listing
        minimal 6 bulan maka anda berhak mendapatkan diskon spesial. Silahkan hubungi CS kami
        melalui tombol yang disediakan untuk mendapatkan kode promo.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image60.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Tekan tombol “Lanjut ke Checkout”</li>
      <br />
      <li>Lanjut ke bagian Cara Pembayaran</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
