<template>
  <div>
    <h3>
      <b>{{ $t('FAQ.deactivate') }}</b>
    </h3>
    <hr />
    <h3>
      <b>9.1 Cara Menurunkan Iklan Listing melalui Aplikasi Mobile (iOS/Android)</b>
    </h3>
    <br />
    <ul>
      <li>
        Pilih menu “Listing” di bagian bawah, kedua dari kanan di beranda melalui Aplikasi Mobile
        (iOS/Android)
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image19.jpg"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>
        Tekan tombol titik tiga “⋮” di sisi kanan atas listing card yang Ingin anda turunkan
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image7.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih Opsi “Nonaktifkan”</li>
      <br />
      <li>
        Akan muncul Pop Up informasi apakah anda yakin untuk menurunkan listing tersebut.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image26.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih “Nonaktifkan”</li>
    </ul>
    <hr />
    <h3>
      <b>9.2 Cara Menurunkan Iklan Listing melalui Browser Laptop/PC</b>
    </h3>
    <br />
    <ul>
      <li>
        Dibagian beranda, pada bagian bawah anda akan langsung menemukan list listing aktif anda
        saat ini
      </li>
      <br />
      <li>
        Tekan tombol titik tiga “⋮” di sisi kanan atas listing card yang Ingin anda turunkan
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image27.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih Opsi “Deaktivasi Listing”</li>
      <br />
      <li>
        Akan muncul Pop Up informasi apakah anda yakin untuk menurunkan listing tersebut.
        <div class="wrapper--img">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/faq/image28.png"
            alt=""
          />
        </div>
      </li>
      <br />
      <li>Pilih “Ya”</li>
    </ul>
    <hr />
  </div>
</template>

<script>
export default {
  name: 'AccountPurchase',
};
</script>

<style></style>
